<!-- Template -->
<template>
  <div id="l-logged" class="l-logged">
    <aside class="l-logged__aside">
      <nav class="l-logged__aside__container">
        <OrganismListMenu />
      </nav>
    </aside>

    <div class="l-logged__main">
      <header class="l-logged__main__header">
        <OrganismTopMenu />
      </header>

      <main class="l-logged__main__container">
        <slot></slot>
      </main>
    </div>

    <OrganismSupportMenu />
    
    <AtomHelpModal :isActive="help.isActive" @help:toggle-modal="setHelp"/>

    <OrganismCookieManager />
  </div>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { ref, provide } from 'vue'

// Keys
import { helpKey, menuKey } from '~/keys/layouts/logged'

// Components
import OrganismListMenu from '~/components/transational/organisms/OrganismListMenu/OrganismListMenu.vue'
import OrganismTopMenu from '~/components/transational/organisms/OrganismTopMenu/OrganismTopMenu.vue'
import OrganismSupportMenu from '~/components/transational/organisms/OrganismSupportMenu/OrganismSupportMenu.vue'
import AtomHelpModal from '~/components/transational/atoms/AtomHelpModal/AtomHelpModal.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'

// Logica
const route = useRoute()
useHead({ title: `${route.meta.title} | Alfa Empréstimo Consignado` })

const menu = ref({
  isActive: null,
  list: {
    desktop: [
      { link: '/', icon: 'payment', label: 'Novo Pedido' },
      { link: '/area-logada/meus-pedidos', icon: 'requests', label: 'Meus Pedidos' },
      { link: '/', icon: 'finance', label: 'Open Finance' }
    ],
    mobile: [
      { link: '/', icon: 'payment', label: 'Novo Pedido' },
      { link: '/area-logada/meus-pedidos', icon: 'requests', label: 'Meus Pedidos' },
      { link: '/', icon: 'help', label: 'Ajuda' },
      { link: '/', icon: 'finance', label: 'Open Finance' }
    ]
  }
})
const help = ref({ isActive: null })

function setMenu() {
  menu.value.isActive = !menu.value.isActive
}

function setHelp() {
  help.value.isActive = !help.value.isActive
}

provide(menuKey, { menu, setMenu })
provide(helpKey, { help, setHelp })
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.l-logged {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-content: start stretch;
  width: 100svw;
  height: 100svh;
  background-color: $alfa-neutral-tertiary-4;
  
  @media screen and (min-width: $tablet) {
    grid-template-columns: minmax(min-content, 260px) 1fr;
    grid-template-rows: 1fr;
  }

  &__aside {
    position: relative;
    display: grid;
    max-height: 100svh;
    @include box-shadow();

    @media screen and (min-width: $tablet) {
      box-shadow: none;
    }

    &__container {
      display: grid;
      overflow: hidden;
    }
  }

  &__main {
    display: grid;
    grid-template-rows: 1fr;
    place-content: stretch;
    
    @media screen and (min-width: $tablet) {
      grid-template-rows: auto 1fr;
    }
    
    &__header {
      display: none;
      
      @media screen and (min-width: $tablet) {
        position: relative;
        display: grid;
        place-content: stretch;
        background-color: $alfa-neutral-primary-1;
        @include box-shadow();
      }
    }
    
    &__container {
      display: grid;
      place-content: stretch;
      padding: 40px 24px 40px;
      overflow: hidden auto;
      @include scrollbar();
      @include scrollbox-shadow($alfa-neutral-tertiary-3);
      
      @media screen and (min-width: $min-tablet) {
        padding: 40px 32px 40px;
      }
      
      @media screen and (min-width: $tablet) {
        padding: 40px;
      }
    }
  }
}
</style>