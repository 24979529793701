<!-- Template -->
<template>
  <div class="o-list-menu">
    <div class="o-list-menu__icon">
      <NuxtLink to="/"><IconConsignado /></NuxtLink>
    </div>

    <button class="o-list-menu__button" type="button" @click="handleMenu">
      <i class="o-list-menu__button__icon" for="menu">
        <IconMenuOpen :width="20" :height="20" />
      </i>

      <span class="o-list-menu__button__title">Menu</span>
    </button>
    
    <ul class="o-list-menu__list">
      <li class="o-list-menu__list__item" v-for="(item, index) in menuList">
        <AtomListButton 
          :key="index"
          :link="item.link"
          :icon="item.icon"
          :label="item.label"
          :type="1"
        />
      </li>
    </ul>

    <div class="o-list-menu__exit">
      <button class="o-list-menu__exit__button" type="button">
        <i class="o-list-menu__exit__button__icon">
          <IconExit :width="34" :height="30" />
        </i>

        <span class="o-list-menu__exit__button__title">
          Sair
        </span>
      </button>
    </div>
  </div>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { inject } from 'vue'

// Keys
import { menuKey } from '~/keys/layouts/logged'

// Icones
import IconConsignado from '~/assets/icons/IconConsignado.vue'
import IconMenuOpen from '~/assets/icons/IconMenuOpen.vue'
import IconExit from '~/assets/icons/IconExit.vue'

// Componentes
import AtomListButton from '~/components/transational/atoms/AtomListButton/AtomListButton.vue';

// Logica
const { menu, setMenu } = inject(menuKey)
const menuList = [...menu.value.list.desktop]

function handleMenu() {
  setMenu()
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-list-menu {
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: $alfa-brand-primary-1;
  padding: 32px 32px 24px;
  
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    row-gap: 32px;
    padding: 40px 0;
  }

  &__icon {
    display: grid;
    place-content: center;
  }

  &__button {
    display: grid;
    place-self: center end;
    place-items: center;
    row-gap: 8px;
    width: 50px;
    background-color: transparent;

    @media screen and (min-width: $tablet) {
      display: none;
    }

    &__icon {
      @include button-round();
    }

    &__title {
      @include font-body-detail(center, 'Montserrat', $alfa-neutral-primary-1);
    }
  }

  &__list {
    display: none;

    @media screen and (min-width: $tablet) {
      display: grid;
      place-content: start stretch;
    }

    &__item {
      display: grid;
      place-content: stretch;
    }
  }

  &__exit {
    display: none;

    @media screen and (min-width: $tablet) {
      display: grid;
      place-content: center;
    }

    &__button {
      display: grid;
      grid-auto-flow: column;
      place-items: center;
      column-gap: 16px;
      background-color: transparent;
      border: none;

      &__icon {
        display: block;
        width: 34px;
        height: 30px;
      }

      &__title {
        @include font-body-big-text(left, 'Montserrat', $alfa-neutral-primary-1);
      }
    }
  }
}
</style>