<!-- Template -->
<template>
  <div 
    class="a-help-modal"
    :class="[
      { 'a-help-modal--in': isActive === true },
      { 'a-help-modal--out': isActive === false }
    ]"
  >
    <div class="a-help-modal__wrapper">
      <button class="a-help-modal__wrapper__close" type="button" @click="handleHelp">
        <IconCancelCircle :width="25" :height="25" />
      </button>

      <h1 class="a-help-modal__wrapper__title">
        Ajuda
      </h1>

      <div class="a-help-modal__wrapper__content">
        <p>
          Está com alguma dúvida em nossa plataforma de simulação? Entre em contato com o Alfa consignado:
        </p>
        <p>
          <strong>SAC Financeira Alfa</strong>
          <span>Dias úteis, das 8h às 18h</span>
          <span><u>4004-0044</u><small>(captais e regiões metropolitanas)</small></span>
          <span><u>0800 725 0044</u><small>(demais localidades)</small></span>
        </p>
      </div>

      <button class="a-help-modal__wrapper__button" type="button" @click="handleHelp">
        Fechar
      </button>
    </div>
  </div>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { defineProps, defineEmits } from 'vue'

// Icones
import IconCancelCircle from '~/assets/icons/IconCancelCircle.vue'

// Props
const { isActive } = defineProps({
  isActive: { type: Boolean, default: false }
})

// Emits
const emit = defineEmits(['help:toggle-modal'])

// Lógica
function handleHelp() {
  emit('help:toggle-modal')
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-help-modal {
  z-index: 997;
  position: fixed;
  top: 0;
  left: 0;
  place-items: end center;
  width: 100svw;
  height: 100svh;
  
  @media screen and (min-width: $tablet) {
    place-items: center;
    padding: 0 60px;
  }

  &:not(:is(&--in, &--out)) {
    display: none;
  }

  &:is(&--in, &--out) {
    display: grid;
  }

  &--in {
    @include animate-modal-toggle(1);
  }

  &--out {
    @include animate-modal-toggle(2, 500);

    @media screen and (min-width: $tablet) {
      @include animate-modal-toggle(2);
    }
  }

  &::before {
    z-index: 998;
    position: absolute;
    content: '';
    display: grid;
    width: 100%;
    height: 100%;
    background-color: rgba($alfa-brand-primary-1, 0.25);
  }

  &--in &__wrapper {
    transform: translateY(100%);
    @include animate-vertical-translate(1, 350);

    @media screen and (min-width: $tablet) {
      @include animate-vertical-translate(1);
    }
  }
  
  &--out &__wrapper {
    @include animate-vertical-translate(2);
  }
  
  &__wrapper {
    z-index: 999;
    display: grid;
    place-content: start stretch;
    row-gap: 32px;
    padding: 40px;
    border-radius: 24px 24px 0 0;
    background-color: $alfa-support-1;
    @include box-shadow(#000000, 0, 32px, 64px);
    
    @media screen and (min-width: $tablet) {
      row-gap: 16px;
      width: clamp(334px, 100%, 904px);
      padding: 40px 60px;
      border-radius: 5px;
      @include box-shadow(#00468233, 0, 3px, 4px);
    }

    &__close {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      display: block;
      width: 25px;
      height: 25px;
      background-color: unset;
      border: 0;
    }

    &__title {
      @include font-title-modal(center, 'Montserrat SemiBold', $alfa-brand-primary-1);

      @media screen and (min-width: $tablet) {
        @include font-simulation-resume(center, 'Montserrat Bold', $alfa-brand-primary-1);
      }
    }

    &__content {
      display: grid;
      place-items: start stretch;
      row-gap: 24px;

      p {
        @include font-body(justify, 'Montserrat', $alfa-brand-primary-1);

        &:nth-child(2) {
          display: grid;
          place-content: start stretch;
          row-gap: 8px;
        }

        strong {
          @include font-body-big-text(justify, 'Montserrat SemiBold', $alfa-brand-primary-1);
        }

        span {
          display: inline-grid;
          grid-auto-flow: column;
          place-content: center start;
          column-gap: 8px;
          @include font-body-big-text(justify, 'Montserrat', $alfa-brand-primary-1);
        }
      }
    }

    &__button {
      place-self: center;
      margin-top: 8px;
      @include button-small(1);
    }
  }
}
</style>
