<!-- Template -->
<template>
  <label class="a-account-options" for="options">
    <input
      class="a-account-options__hidden-input"
      type="checkbox"
      id="options"
      name="options"
      v-model="isOptionsOpened"
    />

    <i 
      class="a-account-options__icon"
      :class="{ 'a-account-options__icon--options-opened': isOptionsOpened }"
    >
      <IconChevronDown :width="20" :height="15" fill="#00305a" />
    </i>

    <picture v-if="accountData.image" class="a-account-options__display">
      <img class="a-account-options__display__image" :src="getImagePath(accountData.image)" />
    </picture>

    <div v-else class="a-account-options__display">
      <span class="a-account-options__display__initials">
        {{ getInitialLetters(accountData.name) }}
      </span>
    </div>
    
    <ul 
      class="a-account-options__list"
      :class="{ 'a-account-options__list--options-opened': isOptionsOpened }"
    >
      <li class="a-account-options__list__item">
        <button class="a-account-options__list__item__button" type="button">
          Sair
        </button>
      </li>
    </ul>
  </label>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { defineProps } from 'vue'

// Icones
import IconChevronDown from '~/assets/icons/IconChevronDown.vue'

// Props
const { accountData } = defineProps({
  accountData: { type: Object, default() {
    return {
      image: null,
      name: 'Banco Alfa Consignado'
    }
  }, required: false }
})

// Logica
const isOptionsOpened = ref(false)

function getInitialLetters(name) {
  const nameArray = name.split(' ')
  const firstLetter = nameArray[0].charAt(0).toUpperCase()
  const lastLetter = nameArray[nameArray.length - 1].charAt(0).toUpperCase()
  return String().concat(firstLetter, lastLetter)
}

function getImagePath(path) {
  return new URL(`/assets/img/${path}`, import.meta.url)
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-account-options {
  cursor: pointer;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  place-content: start stretch;
  place-items: center;
  column-gap: 16px;

  &__hidden-input {
    position: absolute;
    appearance: none;
  }

  &__icon {
    display: block;
    width: 20px;
    height: 15px;
    transition: transform 200ms linear;

    &--options-opened {
      transform: rotate(-180deg) scale(.75);
    }
  }

  &__display {
    display: grid;
    place-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 2px solid $alfa-brand-primary-3;
    background-color: $alfa-brand-primary-1;
    overflow: hidden;

    &__image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__initials {
      user-select: none;
      display: block;
      width: 100%;
      height: 100%;
      @include font-subtitle(center, 'Montserrat SemiBold', $alfa-neutral-primary-1);
    }
  }

  &__list {
    position: absolute;
    right: 0;
    place-content: stretch;
    width: 195px;
    background-color: $alfa-brand-primary-5;
    @include box-shadow();
    
    &:not(:is(&--options-opened)) {
      animation: roll-up 200ms linear forwards;
    }
    
    &--options-opened {
      animation: roll-down 200ms linear forwards;
    }
    
    &__item {
      display: grid;
      grid-auto-flow: column;
      place-content: start stretch;
      
      &__button {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        outline: none;
        padding: 8px 16px;
        @include font-body(left, 'Montserrat SemiBold', $alfa-brand-primary-1);

        &:hover {
          background-color: rgba($alfa-brand-primary-3, 0.5);
        }
      }
    }
  }
}

@keyframes roll-down {
  0% {
    display: none;
    opacity: 0;
    top: 0;
  } 80% {
    opacity: .25;
    top: calc(100%);
  } 100% {
    display: grid;
    opacity: 1;
    top: calc(100% + 16px);
  }
}

@keyframes roll-up {
  0% {
    display: grid;
    opacity: 1;
    top: calc(100% + 16px);
  } 20% {
    opacity: .25;
    top: calc(100%);
  } 100% {
    display: none;
    opacity: 0;
    top: 0;
  }
}
</style>
